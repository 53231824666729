import React from "react";
import BannerText from "../../components/Shared/BannerText";
import RelatedContent from "../../components/Shared/RelatedContent";
import HeroImage from "../../components/Shared/HeroImage";
import * as styles from "./AboutUsPage.module.scss";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface IAboutUsProps {
    data: any;
}

const AboutUsPageTemplate = ({ data }: IAboutUsProps) => {
    const { hero, banner, about, keyValues, keepReading } = data.strapiAboutUsPage;

    return (
        <>
            <HeroImage
                background={hero.headingBackground}
                backgroundMobile={hero.headingBackgroundMobile}
                heading={hero.heading}
                subHeading={hero.subHeadingHTML || hero.subHeading}
                isSerif={true}
            />

            <BannerText background="#133A48" paragraph={banner.paragraphHTML} hasUnderline={true} isFeatured={true} />

            <section className={`${styles.introWrapper} textBox px-3`}>
                <h2 className="font-serif">{about.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: about.paragraphHTML || about.paragraph }} />
            </section>

            <section className={styles.sectionWrapper}>
                <div className="mainBox">
                    <h2 className="font-serif">{keyValues.banner.heading}</h2>
                    <GatsbyImage
                        alt={keyValues.banner.image.alternativeText || "software development company"}
                        image={keyValues.banner.image.file.childImageSharp.gatsbyImageData}
                        className={styles.bannerImg}
                    />
                </div>
            </section>

            <section>
                <div className={`${styles.keyValueWrapper} textBox`}>
                    {keyValues.content.map((item: { title: any; paragraphHTML: any; paragraph: any }, index: any) => (
                        <div className={styles.keyValueTitle} key={index}>
                            <h2 className={styles.keyValueItem}>
                                <span className={styles.keyValueNumber}>{`0${index + 1}`}</span>
                                {`${item.title}`}
                            </h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.paragraphHTML || item.paragraph,
                                }}
                            />
                        </div>
                    ))}
                </div>
            </section>

            <RelatedContent heading={keepReading.heading} content={keepReading.content} />
        </>
    );
};

export default AboutUsPageTemplate;

export const query = graphql`
    query AboutUs($id: String!) {
        strapiAboutUsPage(id: { eq: $id }) {
            hero {
                heading
                subHeadingHTML
                headingBackground {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                headingBackgroundMobile {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            banner {
                paragraphHTML
            }
            about {
                paragraphHTML
                title
                paragraph
            }
            keyValues {
                content {
                    title
                    paragraphHTML
                }
                banner {
                    heading
                    image {
                        alternativeText
                        file {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
            keepReading {
                heading
                content {
                    heading
                    slug
                    image {
                        alternativeText
                        file {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
            id
            nav
            themes {
                navbar
                footer
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id="site-title">{seo.title}</title>
            <meta id="desc" name="description" content={seo.description} />
            <meta id="tw-card" name="twitter:card" content={seo.twitter.card} />
            <meta id="tw-site" name="twitter:site" content={seo.twitter.site} />
            <meta id="tw-creator" name="twitter:creator" content={seo.twitter.creator} />
            <meta id="tw-title" name="twitter:title" content={seo.twitter.title} />
            <meta id="tw-desc" name="twitter:description" content={seo.twitter.description} />
            <meta id="tw-img" name="twitter:image" content={seo.twitter.image.url} />
            <meta id="fb-type" property="og:type" content={seo.facebook.type} />
            <meta id="fb-title" property="og:title" content={seo.facebook.title} />
            <meta id="fb-desc" property="og:description" content={seo.facebook.description} />
            <meta id="fb-url" property="og:url" content={seo.facebook.url} />
            <meta id="fb-img" property="og:image" content={seo.facebook.image.url} />
        </SiteMetadataHead>
    );
}
